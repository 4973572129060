/* css/box.css */

/* UIbox layout */
.hbox{display:flex; justify-content:flex-start; align-items:stretch; flex-direction:row;}
.vbox{display:flex; justify-content:flex-start; align-items:stretch; flex-direction:column;}
.client{display:flex; flex:1;position:relative; height:100%; width:100%;}

[valign="top"], .v-align--top {align-items:flex-start;}
[valign="bottom"], .v-align--bottom {align-items:flex-end;}
[valign="center"], .v-align--center {align-items:center;}

[halign="left"], .h-align--left {justify-content:flex-start;}
[halign="right"], .h-align--right {justify-content:flex-end;}
[halign="center"], .h-align--center {justify-content:center;}

.vbox[layout="space-around"], .hbox[layout="space-around"] {justify-content:space-around;}
.vbox[layout="space-between"], .hbox[layout="space-between"]{justify-content:space-between;}
.vbox[layout="center center"], .hbox[layout="center center"]{justify-content:center; align-items:center;}

.spacer{flex-grow: 1;}

[box-test]{ border: solid 1px red; padding: 2px; }

.container{
    display: block;
    overflow-y: auto;
    padding-bottom:40px;
}

.child-spaced>* {
    margin-left: 8px;
    margin-top: 8px;
}

svg {
    fill: currentColor;
}

i.icon {
    width: 28px;
    height: 28px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
i.icon img {
    width: 100%;
    height: 100%;
}

hr {
    border-color: #f7f7f85e;
}

.theme-dark hr {
    border-color: #8080805e;
}

.bg-transparent {
    background: transparent!important;
}

.ui-modal-overlay {
    position: absolute;
    background: rgba(0,0,0,.4);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}
.ui-modal {
    position: absolute;
    display: block;
    background: var(--color-background-default);
}

.ui-dialog {
    min-width: 250px;
    max-width: 600px;
    box-shadow: var(--f7-elevation-2);
    background: var(--f7-card-bg-color);
    border-radius: var(--f7-dialog-border-radius);
    overflow: hidden;
    margin: 20px;
}
.ui-dialog-title {
    padding: 10px 16px;
    text-align: center;
}
.md .ui-dialog-title {
    text-align: left;
}
.ui-dialog-body {
    text-align: center;
    padding: 8px 16px;
    white-space: pre-wrap;
}
.md .ui-dialog-body {
    text-align: left;
}
.ui-dialog-footer {
    display: flex;
    justify-content: center;
    padding: 2px 8px 8px;    
}
.ios .ui-dialog-footer {
    padding: 0;
    border-top: 1px solid #bbbbbbc2;
}
.md .ui-dialog-footer {
    justify-content: flex-end;
}
.ui-dialog-footer .button {
    height: 42px;
    flex: 1;
    border-right: solid 1px #e5e5e5;
    border-radius: 0;
}
.md .ui-dialog-footer .button {
    height: 42px;
    flex: none;
    border-right: initial;
    border-radius: initial;
    margin-left: 20px;
}
.md .ui-dialog-footer .button:nth-last-child(1) {
    background-color: var(--f7-button-fill-bg-color, var(--f7-theme-color));
    color: var(--f7-button-fill-text-color, #fff);
}

.md .ui-popup {
    border-radius: 0;
}
.ui-popup-transparent {
    box-shadow: none;
    background: transparent;
}

button {
    color: var(--ui-messagebar-buttons-bg-color);
}

button:disabled{
    color: var(--ui-messagebar-buttons-disabled-bg-color);
    cursor: not-allowed;
}