:root {
    --transition-duration: .2s;
}

/* fade-in */
.fade-in-enter {
    opacity: 0;
}
.fade-in-active {
    transition: opacity .2s;
    opacity: 1;
}

/* fade-out */
.fade-out-active {
    transition: opacity .2s;
    opacity: 0;
}

/* slide-left */
.slide-left {
    transition: transform .2s;
    transform: translate3d(-100%, 0, 0);
}

/* slide-right */
.slide-right {
    transition: transform .2s;
    transform: translate3d(100%, 0, 0);
}

/* slide-top */
.slide-top {
    transition: transform .2s;
    transform: translate3d(0, -100%, 0);
}

/* slide-bottom */
.slide-bottom {
    transition: transform .2s;
    transform: translate3d(0, 100%, 0);
}

/* menu-left-bottom */
.menu-left-bottom-enter {
    opacity: 0;
    transform-origin: left top;
    transform: scale(.1);
}
.menu-left-bottom-active {
    transition: all .2s;
    opacity: 1;
    transform: scale(1);
}

/* menu-left-top */
.menu-left-top-enter {
    opacity: 0;
    transform-origin: left bottom;
    transform: scale(.1);
}
.menu-left-top-active {
    transition: all .2s;
    opacity: 1;
    transform: scale(1);
}

/* menu-right-bottom */
.menu-right-bottom-enter {
    opacity: 0;
    transform-origin: right top;
    transform: scale(.1);
}
.menu-right-bottom-active {
    transition: all .2s;
    opacity: 1;
    transform: scale(1);
}

/* menu-right-top */
.menu-right-top-enter {
    opacity: 0;
    transform-origin: right bottom;
    transform: scale(.1);
}
.menu-right-top-active {
    transition: all .2s;
    opacity: 1;
    transform: scale(1);
}

/* menu-center-top */
.menu-center-top-enter {
    opacity: 0;
    transform-origin: center bottom;
    transform: scale(.1);
}
.menu-center-top-active {
    transition: all .2s;
    opacity: 1;
    transform: scale(1);
}

.popup-bottom-top {
    transform: translateY(70%);
    opacity: 0;
}
.popup-bottom-top-active {
    transition: all .2s;
    transform: translateY(0);
    opacity: 1;
}

.popup-right-left {
    transform: translateX(90%);
    opacity: 0;
}
.popup-right-left-active {
    transition: all .2s;
    transform: translateX(0);
    opacity: 1;
}


/* fade */
.transition__fade__in {
    opacity: 0;
}
.transition__fade__in-active {
    transition: opacity var(--transition-duration);
    opacity: 1;
}
.transition__fade__out-active {
    transition: opacity var(--transition-duration);
    opacity: 0;
}

/* scale opacity */
.transition__scale-opacity__in {
    opacity: 0;
    transform: scale(1.5);
}
.transition__scale-opacity__in-active {
    transition: opacity var(--transition-duration), transform var(--transition-duration);
    opacity: 1;
    transform: scale(1);
}
.transition__scale-opacity__out-active {
    transition: opacity var(--transition-duration), transform var(--transition-duration);
    opacity: 0;
    transform: scale(1.5);
}

.md .transition__scale-opacity__in {
    opacity: 0;
    transform: scale(.2);
}
.md .transition__scale-opacity__in-active {
    transition: opacity var(--transition-duration), transform var(--transition-duration);
    opacity: 1;
    transform: scale(1);
}
.md .transition__scale-opacity__out-active {
    transition: opacity var(--transition-duration), transform var(--transition-duration);
    opacity: 0;
    transform: scale(.2);
}

/* slide-bottom */
.transition__slide-bottom__in {
    opacity: 0;
    transform: translate3d(0px, 100%, 0);
}
.transition__slide-bottom__in-active {
    transition: opacity .4s, transform .4s;
    opacity: 1;
    transform: translate3d(0px, 0, 0);
}
.transition__slide-bottom__out-active {
    transition: opacity .4s, transform .4s;
    opacity: 0;
    transform: translate3d(0px, 100%, 0);
}