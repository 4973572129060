.ui-page {
    position: relative;
    background-color: var(--color-background-default);
}

.ui-page-header {
    position: absolute;
    width: 100%;
    z-index: 18;
}

.ui-page-content {
    overflow: auto;
    display: block;
    box-sizing: border-box;
}