.ui-header {
    width: 100%;
    position: relative;
    margin: 0;
    transform: translate3d(0,0,0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 600;
    box-sizing: border-box;
    left: 0;
    height: var(--f7-toolbar-height);
    /* background-image: var(--f7-toolbar-bg-image,var(--f7-bars-bg-image)); */
    background-color: var(--color-primary);
    color: var(--color-primary-contrast-text);
    font-size: var(--f7-toolbar-font-size);
    align-items: center;
    border-bottom: solid 1px var(--f7-navbar-border-color,var(--f7-bars-border-color));
    padding: 0 16px;
}

.ui-header-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex: 1 1 0%;
    min-width: 1px;
    max-width: 100%;
    font-size: 21px;
    font-weight: 400;
    letter-spacing: 0.01em;
    padding: 0 12px;
}

.ui-header button {
    border-radius: 50%;
    padding: 0;
    margin: 0;
    width: 32px;
    height: 32px;
}
.ui-header i {
    margin-left: 0!important;
}

.md .ui-header-shadow, .md [scroll-10] .ui-header-shadow-scroll {
    transition: box-shadow .4s;
    border-bottom: none;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 21%);
}
