/* main */
.ios {
    --color-primary: #565e73;
    --color-primary-contrast-text: #fff;

    --color-secondary: #3ebee1;
    --color-secondary-contrast-text: rgba(0, 0, 0, 0.87);

    --color-error: #f44336;
    --color-error-contrast-text: #fff;

    --color-warning: #ff9800;
    --color-warning-contrast-text: rgba(0, 0, 0, 0.87);

    --color-info: #2196f3;
    --color-info-contrast-text: #fff;

    --color-success: #4caf50;
    --color-success-contrast-text: rgba(0, 0, 0, 0.87);

    --color-background-default: #fafafa;
    --color-background-paper: #a9e862;

    --color-text-primary: rgba(0, 0, 0, 0.87);
    --color-text-seconday: rgba(0, 0, 0, 0.54);
    --color-text-disabled: rgba(0, 0, 0, 0.38);
    --color-text-hint: rgba(0, 0, 0, 0.38);

    --color-divider: rgba(0, 0, 0, 0.12);

    --color-gray-0: #7a7a7a;
    --color-gray-1: #9e9e9e;
    --color-gray-2: #b6b6b6;
    --color-gray-3: #ececec;
    --color-gray-4: #d4d1d1;
}

/* light */
.ios-light {
    --color-primary: rgb(119, 126, 143);
    --color-secondary: rgb(100, 203, 231);
    --color-error: #e57373;
    --color-warning: #ffb74d;
    --color-info: #64b5f6;
    --color-success: #81c784;
}

/* dark */
.ios {
    --color-primary: rgb(60, 65, 80);
    --color-secondary: rgb(43, 133, 157);
    --color-error: #d32f2f;
    --color-warning: #f57c00;
    --color-info: #1976d2;
    --color-success: #388e3c;
}