/* main */
.nina {
    --color-primary: #565e73;
    --color-primary-contrast-text: #fff;

    --color-secondary: #3ebee1;
    --color-secondary-contrast-text: rgba(0, 0, 0, 0.87);

    --color-error: #f44336;
    --color-error-contrast-text: #fff;

    --color-warning: #ff9800;
    --color-warning-contrast-text: rgba(0, 0, 0, 0.87);

    --color-info: #2196f3;
    --color-info-contrast-text: #fff;

    --color-success: #4caf50;
    --color-success-contrast-text: rgba(0, 0, 0, 0.87);

    --color-background-default: url('./svg/background-bg.svg') 100% 100%;
    --color-background-paper: #C7F464;

    --color-text-primary: rgba(0, 0, 0, 0.87);
    --color-text-seconday: rgba(0, 0, 0, 0.54);
    --color-text-disabled: rgba(0, 0, 0, 0.38);
    --color-text-hint: rgba(0, 0, 0, 0.38);

    --color-divider:rgb(60 65 80 / 56%);

    --color-gray-0: #7a7a7a;
    --color-gray-1: #9e9e9e;
    --color-gray-2: #b6b6b6;
    --color-gray-3: #ececec;
    --color-gray-4: #d4d1d1;

    /* ui-chat */
    --ui-chat-messagebar-border-top: solid 1px var(--color-divider);
    --ui-chat-messagebar-textarea-border: solid 1px var(--color-divider); 
    --ui-messagebar-textarea-bg-color: #FFF;
    --ui-messagebar-textarea-disabled-bg-color: var(--color-gray-1);
    --ui-messagebar-buttons-bg-color: var(--color-primary);
    --ui-messagebar-buttons-disabled-bg-color: #3c4150e6;
    --ui-chat-messagebar-background: var(--color-background-default);
    --ui-chat-sender-background: var(--color-background-paper);
    --ui-chat-sender-color: var(--color-secondary-contrast-text);
    --ui-chat-received-background: var(--color-secondary);
    --ui-chat-received-color: var(--color-secondary-contrast-text);
    --ui-chat-background: var(--color-background-default);
    --ui-chat-button-option-bg-color: #d2f2f8a3;
}

/* light */
.nina-light {
    --color-primary: rgb(119, 126, 143);
    --color-secondary: #d2f2f8;
    --color-error: #e57373;
    --color-warning: #ffb74d;
    --color-info: #64b5f6;
    --color-success: #81c784;
}

/* dark */
.nina {
    --color-primary: rgb(60, 65, 80);
    --color-secondary: #d2f2f8;
    --color-error: #d32f2f;
    --color-warning: #f57c00;
    --color-info: #1976d2;
    --color-success: #388e3c;
}

#root {
    background: url('./svg/background-bg.svg') 100% 100%;
}

@media only screen and (min-width: 900px) {
    #root {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px;
        background: #767a88;
    }
    
    .app {
        position: relative!important;
        width: 900px;
        border: solid 1px #3c415063;
        border-radius: 6px;
        overflow: hidden;
        background: url('./svg/background-bg.svg') 100% 100%;
        box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    }
}